<template>
  <main>
    <package-header title="Searches &amp; STCs" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">

          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link to="/pages/searches-stc/purchase" class="nav-link active">
                Purchase
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/searches-stc/manage-existing" class="nav-link">
                Manage Purchases
              </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">

            <!-- Data entry form -->
            <div class="col-md-8">

              <h2>1 - Select the items you want to purchase</h2>
              <h4 class="mt-4">Title Searches</h4>

              <div class="row mb-4">
                <div class="col-sm-6">
                  <div class="custom-control custom-checkbox">
                    <input id="preSearch" type="checkbox" class="custom-control-input" v-model="preSearch">
                    <label class="custom-control-label" for="preSearch">Pre-Search
                      <span class="form-text">Search and be billed immediately</span>
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox mt-1 ml-4" v-if="preSearch == true">
                    <input id="includeCancelledCharges" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="includeCancelledCharges">Include cancelled charges
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="custom-control custom-checkbox">
                    <input id="postSearch" type="checkbox" class="custom-control-input" v-model="postSearch">
                    <label class="custom-control-label" for="postSearch">Post-Search
                      <span class="form-text">Billed after package is received</span>
                    </label>
                  </div>
                  <div class="ml-4 mt-1" v-if="postSearch == true">
                    <div class="d-flex">
                      <select class="form-control mr-1" style="width: 5rem" id="exampleFormControlSelect1">
                        <option>15</option>
                        <option>30</option>
                        <option>60</option>
                        <option>90</option>
                        <option>120</option>
                      </select>
                      <label for="">minutes after package is received</label>
                    </div>

                    <div class="custom-control custom-checkbox mt-1">
                      <input id="includeCancelledCharges2" type="checkbox" class="custom-control-input">
                      <label class="custom-control-label" for="includeCancelledCharges2">Include cancelled charges
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h4 class="mt-2">Other Requests
                <a class="collapse-toggle" href="#collapseExample" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                </a>
              </h4>

              <div class="collapse" id="collapseExample">
                <div class="card card-body bg-light pt-2 mb-3">
                  <strong>Other requests help text</strong>
                </div>
              </div>

              <div class="row pb-3">
                <div class="col-sm-6">
                  <div class="custom-control custom-checkbox">
                    <input id="certification" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="certification">Certification
                    </label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="custom-control custom-checkbox">
                    <input id="parcelActivity" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="parcelActivity">Parcel Activity Notifier Subscription
                    </label>
                  </div>
                </div>
              </div>

              <hr>

              <h2 class="mt-4">2 - Select the PID for your order</h2>
              <table class="table table-full table-stacked table-select-row mb-4">
                <thead>
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input id="th" type="checkbox" class="custom-control-input">
                        <label class="custom-control-label" for="th">&nbsp;
                        </label>
                      </div>
                    </th>
                    <th scope="col">PID and Short Legal Description</th>
                    <th scope="col">Title and Registered Owner</th>
                    <th class="text-right" scope="col">Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems">
                    <td scope="row">
                      <div class="custom-control custom-checkbox">
                        <input :id="index" type="checkbox" class="custom-control-input">
                        <label class="custom-control-label" :for="index">&nbsp;
                        </label>
                      </div>
                    </td>
                    <td data-header="PID and Short Legal Description">
                      <span class="td-content">{{ item.b }}</span>
                    </td>
                    <td data-header="Title and Registered Owner">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                    <td data-header="Fee">
                      <span class="td-content">{{ item.d }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-4">
              <div class="card bg-medium border-0 sticky-top">
                <div class="card-body">
                  <h3 class="card-title">Order Summary</h3>

                  <dl class="mt-2">
                    <dt>File Reference</dt>
                    <dd>ABC123</dd>
                  </dl>

                  <div class="d-flex justify-content-between">
                    <span>Product Fee:</span>
                    <span>$9.25</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Service Charge:</span>
                    <span>$6.00</span>
                  </div>

                  <hr>

                  <div class="d-flex justify-content-between">
                    <span>Subtotal:</span>
                    <span>$15.25</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>GST:</span>
                    <span>$0.32</span>
                  </div>

                  <hr>

                  <div class="d-flex justify-content-between">
                    <strong>Total</strong>
                    <strong>$15.57</strong>
                  </div>

                  <dl class="mt-2">
                    <dt>GST Registration Number</dt>
                    <dd>81630 4414 RT0001</dd>
                  </dl>

                  <router-link class="d-block" to="/pages/searches-stc/review">Review Order Details
                  </router-link>

                  <button class="btn btn-primary mt-2">Purchase (1)</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      preSearch: false,
      postSearch: false,
      tableItems: [
        {
          a:'1',
          b:'015-671-569 S/1592///A//8',
          c:'KT119399 NE Registered Sm*, P*',
          d:'$6.00',
        },
        {
          a:'2',
          b:'015-671-570 S/1592///A//9',
          c:'KT119372 NE Registered Ar*, J*',
          d:'',
        },
        {
          a:'3',
          b:'015-671-571 S/1592///A//10',
          c:'KT119348 NE Registered Da*, S*',
          d:'',
        },
        {
          a:'4',
          b:'015-671-572 S/1592///A//11',
          c:'KT119321 NE Registered Di*, S*',
          d:'',
        }
      ]
    }
  }
}
</script>

